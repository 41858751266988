import { useState } from "react";
import { Radio, RadioGroup } from "react-aria-components";
import { ActionMenu } from "../../components/ActionMenu.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { WModalButton } from "../../components/button/WToolButton.tsx";
import RA from "../../components/forms/Radio.module.css";
import R from "../../components/forms/Radiogroup.module.css";
import { WCheckbox } from "../../components/forms/WCheckbox.tsx";
import { WSearchField } from "../../components/forms/WSearchField.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { DeleteModal } from "../../components/modal/DeleteModal.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { WTable, WTableBody } from "../../components/table/WTable.tsx";
import { TODO } from "../../dev/Todo.tsx";
import { todoAction } from "../../dev/todoSubmit.ts";
import { AdminTitle } from "../AdminTitle.tsx";

export function BaremetalPoolsView() {
    const [queryString, setQueryString] = useState("");

    const items = [
        {
            id: "uuid",
            title: "UUID",
        },
    ];

    return (
        <>
            <AdminTitle title="Baremetal Pools">
                <WModal button={<WModalButton label="New" color="primary" size="bar" variant="basic" />}>
                    <WModalContent title="Add Baremetal Pool" label="Add" modalAction={todoAction}>
                        <TODO />
                    </WModalContent>
                </WModal>
            </AdminTitle>

            <WTable adminTable search={<WSearchField value={queryString} onChange={setQueryString} />}>
                <thead>
                    <tr>
                        <th>Name (UUID)</th>
                        <th>UI Position</th>
                        <th>Description</th>
                        <th>Details</th>
                        <th>Pool is visible</th>
                        <th>Default</th>
                        <th>Servers available</th>
                        <th className="text-right">
                            <ActionMenu header="Filter" onAction={todoAction} selectedKeys={[]} items={items}>
                                <WButton
                                    color="muted"
                                    variant="ghost"
                                    size="xs"
                                    icon="jp-icon-toggles size-0.75rem"
                                    ariaLabel="Filter"
                                    action={undefined}
                                />
                            </ActionMenu>
                        </th>
                    </tr>
                </thead>
                <WTableBody>
                    <tr>
                        <td>
                            <div className="flex items-center gap-0.375em">
                                <MaskIcon className="jp-hostpool-icon color-primary size-0.75rem" />
                                <div>
                                    AI Metal
                                    <br />
                                    34545745863568356745...
                                </div>
                            </div>
                        </td>
                        <td>0</td>
                        <td>Best for inferen...</td>
                        <td>AMD 9124, 16 C...</td>
                        <td>
                            <WCheckbox isSelected={true} onChange={todoAction}></WCheckbox>
                        </td>
                        <td>
                            <RadioGroup
                                className={R.RadioGroup}
                                aria-label="Set as default"
                                value="TODO"
                                onChange={todoAction}
                            >
                                <Radio className={RA.Radio} value="no"></Radio>
                            </RadioGroup>
                        </td>
                        <td>2/5</td>
                        <td className="text-right">
                            <WModal button={<WModalButton label="Edit" inTable icon="jp-icon-edit" />}>
                                <WModalContent title="Edit Baremetal Pool" label="Edit" modalAction={todoAction}>
                                    <TODO />
                                </WModalContent>
                            </WModal>

                            <DeleteModal title="Delete Baremetal Pool" inTable modalAction={todoAction}>
                                <p>Are you sure you want to delete baremetal pool?</p>
                            </DeleteModal>
                        </td>
                    </tr>
                </WTableBody>
            </WTable>

            <div className="p-2">
                <WModal
                    button={
                        <WModalButton
                            className="w-full"
                            label="Add Baremetal Pool"
                            color="primary"
                            size="lg"
                            variant="dashed"
                            icon="jp-icon-add"
                        />
                    }
                >
                    <WModalContent title="Add Baremetal Pool" label="Add" modalAction={todoAction}>
                        <TODO />
                    </WModalContent>
                </WModal>
            </div>
        </>
    );
}
